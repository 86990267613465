$link-hover-decoration: none;
$font-family-base: "DM Sans", sans-serif;
$font-weight-base: 300;
$headings-font-family: "DM Sans", sans-serif;
$headings-line-height: 1.1;
$line-height-base: 1.5;

$purple-light: #A2C2E8;
$purple-main: #904a4c;
$primary: #521e24;
$purple-dark: #8caf9c;
$pink-dark: #e0bab1;
$tan-dark: #f3ede2;
$green-light: #8caf9c;

$blue-mid: #3574c8;
$blue-dark: #4794b9;
$green-mid: #84b55f;
$green-dark: darken($green-mid, 10%);
$orange-light: #fbb03b;
$orange-mid: #f69353;
$orange-mid-dark: #f37521;
$orange-dark: #d4622a;

$grey-lightest: #f2f2f2;
$grey-light: #f3f4f4;
$grey-light-mid: #999999;
$grey-mid: #808080;
$grey-mid-alt: #6d6e70;
$grey-mid-dark: #4d4d4d;
$grey-dark: #181818;

$navbar-zindex: 1029;

$modal-content-border-radius: 0;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$btn-font-weight: bold;

$input-placeholder-color: $grey-light-mid;

$slick-font-path: "~slick-carousel/slick/fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "~slick-carousel/slick/" !default;
$slick-arrow-color: darken($green-mid, 30%) !default;
$slick-font-family: FontAwesome;
$slick-prev-character: "\f060";
$slick-next-character: "\f061";

$h1-font-size: 2.5vw;
$h2-font-size: 2.25vw;
$h3-font-size: 2vw;
$h4-font-size: 1.75vw;
$h5-font-size: 1.5vw;
$h6-font-size: 1.25vw;

h1 {
  font-weight: bold;
}

h2 {
  font-weight: bold;
}

h3 {
  font-weight: 300;
}

h4 {
  font-weight: 400;
}

h5 {
  font-weight: 400;
}
