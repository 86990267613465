.header {
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  color: #fff;

  &--fixed-icons {
    position: absolute;
    top: 0;
    width: 7.5vw;
    height: 100%;
    right: 2vw;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    @media screen and (max-width: 1441px) {
      width: 12vw;
      right: 26vw;
    }
  }

  .vue-sticky-el {
    transition: all 0.6s ease;
    background: transparent;

    &.top-sticky {
      padding: 15px 0;
      background-color: $primary;
    }
  }

  .header-logo {
    img {
      width: 500px;
      max-width: 100%; //ie fix
    }
  }

  .header-nav {
    display: flex;
    align-items: center;
    a {
      color: #fffefe;
      margin-left: 15px;
      margin-right: 15px;
      font-weight: bold;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .header-buttons {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a {

      &:first-child {
        margin-left: 0;
      }

      margin-right: 15px;

      &.social-link {
        &:last-of-type {
          margin-right: 28px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      a {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

}

.footer {
  padding-top: 15px;
  padding-bottom: 15px;

  .footer-logo {
    display: flex;
    align-items: center;

    img {
      width: 500px;
      max-width: 100%; //ie fix
    }
  }

  .footer-links {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      color: #fffefe;
      margin-left: 15px;
      margin-right: 15px;
      font-weight: bold;
    }
  }

  @include media-breakpoint-down(md) {
    .footer-logo {
      justify-content: center;
      margin-bottom: 30px;
    }
    .footer-links {
      text-align: center;
      flex-wrap: wrap;
      a {
        flex: 100%;
      }
    }
  }
}

.page-section {
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &.columns-section {
    position: relative;
    z-index: 3;
  }

  &#news-feed {
    margin-top: -80px;
    position: relative;
    z-index: 20;
    padding-top: 0;
    padding-bottom: 20vw;
    @include media-breakpoint-down(md) {
      padding-top: 0;
      padding-bottom: 40vw;
    }
  }

  &#get-in-touch {
    padding-top: 20vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 34vw;
      top: -17vw;
      background-image: url("../../images/form-bg-people.png");
      background-size: 102%;
      background-repeat: no-repeat;
      background-position-x: -1vw;
      overflow-x: hidden;

      @include media-breakpoint-down(md) {
        top: -25vw;
        height: 50vh;
        background-size: 150%;
        background-position-x: -25vw;
      }
    }

  }

  &#costs {
    @include media-breakpoint-down(sm) {
      height: auto !important;
    }
  }

  &#about {
    @include media-breakpoint-down(sm) {
      height: auto !important;
    }
  }
}
